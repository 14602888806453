(function () {
  'use strict';

  class EditModuleCtrl {
    constructor(Modules, ModuleSet, $stateParams, Media, $mdToast, $filter) {
      let vm = this;
      vm.ctrlName = 'EditModuleCtrl';
      vm.Modules = Modules;
      vm.ModuleSet = ModuleSet;
      vm.$stateParams = $stateParams;
      vm.$filter = $filter;
      vm.Media = Media;
      vm.$mdToast = $mdToast;
      vm.pattern = /^[0-9]*$/;
      vm.init();
      vm.moduleSetIndex();
    }
    init() {
      const vm = this;
      vm.Modules.moduleView(vm.$stateParams)
        .then((data)=> {
          vm.module = data.module_document;
        });
    }
    moduleSetIndex() {
      const vm = this;
      vm.ModuleSet.moduleSetIndex()
        .then((data) => {
          vm.ModuleSetsPayload = data;
        });
    }
    submitModule(form) {
      const vm = this;
      if (form.$valid) {
        vm.updateModule();
      }
    }
    updateModule() {
      let vm = this;
      vm.Modules.moduleEdit(vm.module)
        .then(()=> {
          vm.message();
        });
    }
    setupCategories() {
      const vm = this;
      if (vm.module.module_set_id === vm.module.module_set.id) {
        if (vm.cacheCategories) {
          vm.module.module_categories = vm.cacheCategories;
          delete vm.cacheCategories;
        }
      } else {
        vm.cacheCategories = angular.copy(vm.module.module_categories);
        const moduleSet = vm.$filter('filter')(vm.ModuleSetsPayload.module_sets, {id: vm.module.module_set_id}, true)[0];
        vm.module.module_categories = moduleSet.module_categories;
      }
    }
    message(msg = 'Module Updated Successfully') {
      const vm = this;
      vm.$mdToast.show(
        vm.$mdToast.simple()
          .textContent(msg)
          .position('bottom right')
          .hideDelay(3000)
      );
    }
  }

  /**
   * @ngdoc object
   * @name learning.editModule.controller:EditModuleCtrl
   *
   * @description
   *
   */
  angular
    .module('learning.editModule')
    .controller('EditModuleCtrl', EditModuleCtrl);
}());
